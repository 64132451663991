// import moment from 'moment'

/**
 * 补充缺失数据---功率跟踪
 * @param {*} list 源数据
 * @param {*} showFalseValue 显示假值
 * @param {*} falseValueColor 假值颜色
 * @returns 
 */
function beautifyPowerAnalysis(list, showFalseValue = false, falseValueColor = '#f00') {
  // body...
  var time = 0;
  if (list.length > 0) {
    time = list[0].recordTime
    for (var i = 0; i < list.length; i++) {
      var item = list[i]
      if (item.recordTime - time < 2 * 60 * 1000) {
        time = item.recordTime;
        var pcsPower_DC = (item.pcsPower_DC / 10).toFixed(2)
        var batterySystemSOC = item.batterySystemSOC
      } else {
        while (time + 60000 < item.recordTime) {
          time += 60000;
          list.splice(i, 0, {
            pcsPower_DC: showFalseValue ? pcsPower_DC : '',
            batterySystemSOC: showFalseValue ? batterySystemSOC : '',
            recordTime: time,
            isFalseValue: true,
            falseValueColor: falseValueColor,
          });
        }
        time = list[i].recordTime;
      }
    }
  }
  return list
}




/**
 * 补充缺失数据---负荷跟踪
 * @param {*} list 源数据
 * @param {*} showFalseValue 显示假值
 * @param {*} falseValueColor 假值颜色
 * @returns 
 */
function beautifyLoadTracking(list, showFalseValue = false, falseValueColor = '#f00') {
  var time = 0;
  if (list.length > 0) {
    time = list[0].recordTime;
    for (var i = 0; i < list.length; i++) {
      if (list[i].recordTime - time < 800000) {
        time = list[i].recordTime;
        var gccm = list[i].gccm;
        var gwm = list[i].gwm;
      } else {
        while (time + 800000 < list[i].recordTime) {
          time += 60000;
          list.splice(i, 0, {
            gccm: showFalseValue ? gccm : '',
            gwm: showFalseValue ? gwm : '',
            recordTime: time,
            isFalseValue: true,
            falseValueColor: falseValueColor,
          });
        }
        time = list[i].recordTime;
      }
    }
  }

  return list
}



export {
  beautifyPowerAnalysis,
  beautifyLoadTracking,
}